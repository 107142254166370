import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import {message} from 'antd';
import {BREAK_POINTS, MEMBERSHIP} from '../../Domain/Constants';
import OrderInfo from '../../Components/OrderInfo';
import CartItem from '../../Components/CartItem';
import * as JStorageActions from 'rev.sdk.js/Actions/JStorage';

function OrderPage(props) {
  const [order, setOrder] = React.useState(null);
  const params = React.useMemo(() => qs.parse(props.location.search), [
    props.location.search,
  ]);
  const hasQuery = Object.keys(params).length > 0;
  const [actions] = useOutlet('actions');

  const getQuery = (queryParams) => {
    if (queryParams?.order_number) {
      return {
        key: 'order_number',
        value: queryParams?.order_number,
      };
    } else if (queryParams?.order_id) {
      return {
        key: 'order_id',
        value: queryParams?.order_id,
      };
    } else {
      return {
        key: 'id',
        value: queryParams?.id,
      };
    }
  };

  const getOrder = React.useCallback(async () => {
    actions.setLoading(true);
    const query = getQuery(params);
    try {
      const resp = await JStorageActions.fetchOneDocument('order', {
        [query.key]: query.value,
      });
      setOrder(resp);
    } catch (e) {
      try {
        const resp = await JStorageActions.fetchOneDocument('checkout', {
          [query.key]: query.value,
        });
        setOrder(resp);
      } catch (e) {
        setOrder(null);
        message.error('無法取得訂單資訊，請確認訂單號碼是否正確');
      }
    } finally {
      actions.setLoading(false);
    }
  }, [actions, params]);

  React.useEffect(() => {
    if (hasQuery) {
      getOrder();
    }
  }, [getOrder, hasQuery]);

  const extraHintText = React.useMemo(() => {
    if (order) {
      if (order.membership !== MEMBERSHIP.normal) {
        return order[`cart_giveaway_hint_${order.membership}`];
      }
    }

    return '';
  }, [order]);

  if (!order) {
    return null;
  }

  return (
    <Wrapper>
      <div className="content" style={{paddingBottom: 0}}>
        <div
          style={{
            padding: '15px 20px',
            border: '1px solid #d6d6d6',
            margin: '10px auto',
            borderRadius: 10,
          }}>
          {order.items.map((cartItem, idx) => (
            <CartItem item={cartItem} itemIdx={idx} key={idx} disabled />
          ))}
          <Section>
            <div className="separate" />
            {order.cart_giveaway_hint_normal && (
              <p
                style={{
                  whiteSpace: 'pre-line',
                  wordBreak: 'break-word',
                  maxWidth: 300,
                  marginLeft: 'auto',
                  color: 'rgba(132, 110, 79, 1)',
                  marginTop: 5,
                }}>{`${order.cart_giveaway_hint_normal}`}</p>
            )}

            {extraHintText && (
              <p
                style={{
                  whiteSpace: 'pre-line',
                  wordBreak: 'break-word',
                  maxWidth: 300,
                  marginLeft: 'auto',
                  color: 'rgba(132, 110, 79, 1)',
                  marginTop: 5,
                }}>{`${extraHintText}`}</p>
            )}

            <div className="field">
              <div className="name">小計</div>
              <div className="value">$ {order.subtotal}</div>
            </div>
            {order.extra_items.map((e, i) => (
              <div className="field" key={i}>
                <div className="name">{e.name}</div>
                <div className="value">$ {e.amount}</div>
              </div>
            ))}
            <div className="field total">
              <div className="name">總金額</div>
              <div className="value">$ {order.total}</div>
            </div>
          </Section>
        </div>
      </div>
      <OrderInfo order={order} />
    </Wrapper>
  );
}

const InputField = styled.div`
  & > label {
    margin-bottom: 3px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 25px;
  }

  & input[disabled] {
    background-color: transparent;
    color: #000;
  }
`;

const Wrapper = styled.div`
  & .content {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
    padding: var(--basePadding);
    padding-top: 0;
    padding-bottom: 80px;

    & > .header {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > .circle {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #846e4f;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        & img {
          width: 96px;
          height: 96px;
        }
      }

      & > h2 {
        margin-bottom: 20px;
        font-size: 20px;
        letter-spacing: 0;
        text-align: center;
        line-height: 30px;
      }

      & > p {
        margin-bottom: 34px;
        font-size: 14px;
        letter-spacing: 0;
        text-align: center;
        line-height: 25px;
      }
    }

    & > .form {
      padding: 30px 50px;
      border: 1px solid #dddddd;
      border-radius: 10px;

      & > h2 {
        font-size: 20px;
        letter-spacing: 0;
        padding-bottom: 10px;
      }

      & > .form-content {
        border-top: 1px solid #846e4f;
        border-bottom: 1px solid #846e4f;

        padding-bottom: 27px;
        padding-top: 15px;

        & > .col {
          margin-left: 35px;
          flex-basis: calc((100% - 70px) / 3);
          @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
            flex-basis: 100%;
            margin-left: 0px;
            margin-bottom: 15px;
          }
        }
      }
      & > .footer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
    padding: 0px;
    & .content {
      padding: 0px;
    }
  }
`;

const Section = styled.section`
  & > .separate {
    height: 1px;
    background-color: #846e4f;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  & > .field {
    padding: 8px 20px;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;

    & > .name {
      font-size: 14px;
    }

    & > .value {
      font-size: 20px;
      color: #846e4f;
      min-width: 150px;
      text-align: right;
    }
  }

  & > .field.total {
    background-color: #1e2f29;
    color: #fff;

    & > .value {
      color: #fff;
      font-size: 30px;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.xss}px) {
    & > .field {
      flex-direction: column;
      padding: 8px 0px;
      & > .name {
        font-size: 14px;
      }

      & > .value {
      }
    }

    & > .field.total {
      padding: 8px 10px;
    }
  }
`;

export default OrderPage;
